import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;

  ul {
    list-style: none;

    display: flex;
    align-items: center;
    gap: 1rem;
  }

  li a {
    padding: 0.5rem;
    cursor: pointer;
    background: #ffffff;
    min-width: 2rem;
    height: 2rem;
    line-height: 1.5rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 0.3125rem;
    color: var(--gray);

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s;
  }

  li:hover:not(.selected) {
    a {
      background: #f8f8f8;
    }
  }

  .previous,
  .next {
    a {
      padding: 0;

      &:hover {
        background: #fff;
      }
    }

    &.disabled {
      display: none;
    }
  }

  .selected {
    a {
      color: #fff;
      background: var(--softBlue);
    }
  }
`;

export const ArrowButton = styled.button`
  border: none;

  background: #fff;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.3125rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;

  color: var(--softBlue);
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: #f8f8f8;
  }
`;
