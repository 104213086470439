import styled from 'styled-components';

import { ReactComponent as SearchIconRaw } from '../../assets/images/drivers/drivers-search-icon.svg';
import { ReactComponent as EyeIconRaw } from '../../assets/images/drivers/drivers-eye-icon.svg';

export const ManageUsersContainer = styled.section`

  width: 100%;
  padding: 0rem 2rem;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ManageUsersContent = styled.div`
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));

  border-radius: 0 1.875rem 1.875rem 1.875rem;
`;

export const TableHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface TabProps {
  $isActive: boolean;
}

export const Tab = styled.button<TabProps>`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;

  background: ${({ $isActive }) => ($isActive ? 'var(--darkPurple)' : 'transparent')};

  border: 2px solid ${({ $isActive }) => ($isActive ? 'var(--darkPurple)' : 'rgba(120, 191, 194, 0.6)')};
  color: ${({ $isActive }) => ($isActive ? 'white' : 'rgba(120, 191, 194, 0.6)')};
  font-size: 1rem;
  font-weight: 500;

  cursor: pointer;
`;

export const SearchBar = styled.form`
  width: 100%;
  max-width: 18.125rem;
  height: 3rem;
  border: 1px solid #eee;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:focus-within {
    border-color: var(--gray);
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 3rem 0 1rem;

  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
`;

export const SearchButton = styled.button`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;

  background: transparent;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const SearchIcon = styled(SearchIconRaw)`
  width: 1.25rem;
  height: 1.25rem;
`;

export const TableWrapper = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: none;
  border-spacing: 0;
  border: 1px solid #eee;

  border-radius: 10px;

  tbody tr:last-child {
    td {
      border-radius: 0 10px 10px 0;
      border-bottom: none;
    }
  }
`;

export const TableHeading = styled.th`
  text-align: left;
  padding: 1rem 2rem;
  font-weight: 500;
  font-size: .9375rem;
  background: #fafafa;
  border-bottom: 1px solid #eee;

  &:first-child {
    border-radius: 10px 0 0 0px;
  }

  &:last-child {
    border-radius: 0 10px 0px 0;
  }

`;

export const TableCell = styled.td`
  text-align: left;
  padding: 1rem  2rem;

  border-bottom: 1px solid #eee;

  font-size: .9375rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* &:last-child {
    text-align: center;
  } */
`;

export const PaginationContainer = styled.div`
  width: 100%;


  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--gray);
`;

export const DetailsButton = styled.button`
  background: #fff;
  border: none;

  border-radius: 4px;
  padding: 0.375rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: var(--darkPurple);

  &:hover {
    background: #f0f0f0;
  }
`;

export const EyeIcon = styled(EyeIconRaw)`
  width: 1.25rem;
  height: 1.25rem;
`;
