import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { ArrowButton, PaginationContainer } from './styles';

interface Props extends ReactPaginateProps {}

export const Pagination = ({ ...props }: Props) => (
  <PaginationContainer>
    <ReactPaginate
      nextLabel={(
        <ArrowButton type="button">
          <FaChevronRight />
        </ArrowButton>
        )}
      previousLabel={(
        <ArrowButton type="button">
          <FaChevronLeft />
        </ArrowButton>
        )}
      {...props}
    />
  </PaginationContainer>
);
