import { UserBox } from '../../molecules/UserBox';

import {
  ButtonsContainer,
  ButtonText, HeaderContainer,
  HeaderWrapper, LinedLink,
  LinkButton, Logo,
} from './styles';

export const Header = () => (
  <HeaderContainer>
    <HeaderWrapper>
      <ButtonsContainer>
        <LinkButton to="/">
          <ButtonText>
            MEU DASHBOARD
          </ButtonText>
        </LinkButton>
        <LinedLink to="/manageDrivers">
          Gerenciar Motoristas
        </LinedLink>
        <LinedLink to="/manageUsers">
          Gerenciar Usuários
        </LinedLink>
      </ButtonsContainer>
      <Logo />
      <UserBox />
    </HeaderWrapper>
  </HeaderContainer>
);
