import { useCallback, useEffect, useState } from 'react';
import { PageTitle } from '../../components/atoms/PageTitle';
import { Pagination } from '../../components/molecules/Pagination/Pagination';
import { UserDetailsModal } from '../../components/organisms/UserDetailsModal/UserDetailsModal';
import { IUser } from '../../contexts/Auth/interfaces';
import useDebounceWithCallback from '../../hooks/useDebounceWithCallback';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { api } from '../../services/api';
import {
  DetailsButton,
  EyeIcon,
  ManageUsersContainer,
  PaginationContainer,
  SearchBar,
  SearchButton,
  SearchIcon,
  SearchInput,
  Tab,
  Table,
  TableCell,
  TableHeader,
  TableHeading,
  TableWrapper,
  TabsContainer,
} from './styles';

export const ManageUsers = () => {
  const [usersResult, setUsersResult] = useState<IPaginatedResult<IUser>>();
  const [selectedRole, setSelectedRole] = useState(2);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  const resetPage = useCallback(() => setPage(1), []);

  const debouncedSearch = useDebounceWithCallback(search, resetPage, 500);

  const getUsers = async () => {
    const roleIds = selectedRole === 2 ? [2] : [3, 5];

    const response = await api.get<IPaginatedResult<IUser>>('/users', {
      params: {
        'custom[roles]': roleIds, // 2 Shipper, 3 Transporter
        'custom[search]': search || undefined,
        sort: 'created_at',
        limit: 10,
        page,
      },
    });

    setUsersResult(response.data);
  };

  const total = usersResult?.total || 0;
  const totalPages = Math.ceil(total / 10);

  useEffect(() => {
    getUsers();
  }, [selectedRole, debouncedSearch, page]);

  return (
    <ManageUsersContainer>
      <PageTitle>Gerenciar Usuários</PageTitle>

      <TableHeader>
        <TabsContainer>
          <Tab $isActive={selectedRole === 2} type="button" onClick={() => setSelectedRole(2)}>
            Embarcadores
          </Tab>
          <Tab $isActive={selectedRole === 3} type="button" onClick={() => setSelectedRole(3)}>
            Transportadores
          </Tab>
        </TabsContainer>

        <SearchBar onSubmit={(e) => {
          e.preventDefault();
        }}
        >
          <SearchInput type="text" placeholder="Pesquisar" onChange={(e) => setSearch(e.target.value)} />
          <SearchButton type="submit">
            <SearchIcon />
          </SearchButton>
        </SearchBar>
      </TableHeader>

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <TableHeading>
                Nome
              </TableHeading>
              <TableHeading>
                E-mail
              </TableHeading>
              <TableHeading>
                Telefone
              </TableHeading>
              {selectedRole === 3 && (
                <TableHeading>
                  Tipo
                </TableHeading>
              )}
              <TableHeading style={{
                textAlign: 'center',
              }}
              >
                Data de cadastro
              </TableHeading>
              {selectedRole === 3 && (
                <TableHeading />
              )}
            </tr>
          </thead>

          <tbody>
            {usersResult?.results?.map((user) => (
              <tr key={user.id}>
                <TableCell>
                  {user.individual_person?.name || user?.legal_person?.corporate_name}
                </TableCell>
                <TableCell>{user.email || ''}</TableCell>
                <TableCell>{user.cellphone || ''}</TableCell>
                {selectedRole === 3 && (
                  <TableCell>
                    {Number(user.role_id) === 5 ? 'Autônomo' : 'Transportador'}
                  </TableCell>
                )}
                <TableCell style={{
                  textAlign: 'center',
                }}
                >
                  {user.created_at ? new Date(user.created_at).toLocaleDateString('pt-BR') : ''}
                </TableCell>
                {selectedRole === 3 && (
                  <TableCell>
                    <DetailsButton
                      type="button"
                      onClick={() => setSelectedUser(user)}
                    >
                      <EyeIcon />
                    </DetailsButton>
                  </TableCell>
                )}

              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>

      <PaginationContainer>
        Mostrando {usersResult?.results?.length} de {total} usuários
        <Pagination
          pageCount={totalPages}
          onPageChange={({ selected }) => setPage(selected + 1)}
          forcePage={page - 1}
        />
      </PaginationContainer>

      {selectedUser && (
        <UserDetailsModal
          userId={selectedUser.id}
          onClose={() => setSelectedUser(null)}
        />
      )}
    </ManageUsersContainer>
  );
};
