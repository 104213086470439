import { useState } from 'react';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

import { BaseInput } from '../../atoms/BaseInput';
import { SubmitButton } from '../../atoms/SubmitButton';

import { ISignInFormProps } from './interfaces';
import {
  CheckBox, CheckContainer,
  CheckLabel, Form,
  FormWrapper, PasswordInputWrapper, ShowPasswordButton, WarningText,
} from './styles';

export const SignInForm = ({
  register, onSubmit, isSubmitting, errors,
}: ISignInFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Form onSubmit={onSubmit}>
      <FormWrapper>
        <BaseInput
          type="email"
          placeholder="E-mail"
          lined
          autoComplete="sign-in-vagoo email"
          error={!!errors?.email}
          {...register('email', { required: true })}
        />
        <WarningText>
          {errors?.email?.message}
        </WarningText>
        <PasswordInputWrapper>
          <BaseInput
            type={showPassword ? 'text' : 'password'}
            placeholder="Senha"
            className="sign-in-form__input"
            lined
            autoComplete="sign-in-vagoo password"
            error={!!errors?.password}
            {...register('password', { required: true })}
          />
          <ShowPasswordButton type="button" onClick={() => setShowPassword((prev) => !prev)}>
            {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
          </ShowPasswordButton>
        </PasswordInputWrapper>
        <WarningText>
          {errors?.password?.message}
        </WarningText>
        <SubmitButton
          type="submit"
          className="sign-in-form__button"
          loading={!!isSubmitting}
          large
        >
          Login
        </SubmitButton>
        <CheckContainer>
          <CheckBox
            type="checkbox"
            id="login-keep"
            className="sign-in-form__check-box"
            {...register('saveUser')}
          />
          <CheckLabel
            htmlFor="login-keep"
            className="sign-in-form__check-label"
          >
            Me mantenha logado
          </CheckLabel>
        </CheckContainer>
      </FormWrapper>
    </Form>
  );
};
