import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IAddress, IUser } from '../../../contexts/Auth/interfaces';
import { api } from '../../../services/api';
import { maskCpfOrCnpj } from '../../../utils/masks';
import {
  CloseButton,
  CloseIcon,
  DetailsContainer,
  DetailsTitle,
  ModalBody,
  ModalHeader,
  Section,
  SectionTitle,
  Table,
  TableCell,
  TableHeading,
  UserDetailsField,
  UserDetailsFieldLabel,
  UserDetailsFieldValue,
  UserDetailsModalContainer,
  UserDetailsModalRoot,
  UserDetailsRow,
} from './styles';

interface IUserDetailsModalProps {
  userId: string;
  onClose: () => void;
}

const formatAddress = (address: IAddress) => `${address.street || ''}, ${address.number || ''} ${address.complement || ''} - ${address.neighborhood || ''} - ${address.cep || ''} - ${address?.city?.name || ''} -  ${address?.city?.state?.uf || ''}`;

const formatUserAddress = (user: IUser) => {
  if (user.individual_person?.address) {
    return formatAddress(user.individual_person.address);
  }

  if (user.legal_person?.address) {
    return formatAddress(user.legal_person.address);
  }

  return '';
};

export const UserDetailsModal = ({ userId, onClose }: IUserDetailsModalProps) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async () => {
    try {
      setIsLoading(true);

      const { data } = await api.get<IUser>(`/users/${userId}`, {
        params: {
          'custom[includeVehicles]': true,
        },
      });

      setUser(data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, [userId]);

  const bankAccount = user?.banks?.[0];

  return (
    <UserDetailsModalRoot>
      <UserDetailsModalContainer>
        <ModalHeader>
          <DetailsTitle>
            Detalhes do usuário
          </DetailsTitle>

          <CloseButton onClick={onClose} type="button">
            <CloseIcon />
          </CloseButton>
        </ModalHeader>

        {user && (
        <ModalBody>
          <DetailsContainer>
            <Section>
              <UserDetailsRow>
                <UserDetailsField>
                  <UserDetailsFieldLabel>
                    Nome
                  </UserDetailsFieldLabel>
                  <UserDetailsFieldValue>
                    {user?.individual_person?.name || user?.legal_person?.corporate_name}
                  </UserDetailsFieldValue>
                </UserDetailsField>
                <UserDetailsField>
                  <UserDetailsFieldLabel>
                    E-mail
                  </UserDetailsFieldLabel>
                  <UserDetailsFieldValue>
                    {user?.email}
                  </UserDetailsFieldValue>
                </UserDetailsField>
                <UserDetailsField>
                  <UserDetailsFieldLabel>
                    Telefone
                  </UserDetailsFieldLabel>
                  <UserDetailsFieldValue>
                    {user?.cellphone}
                  </UserDetailsFieldValue>
                </UserDetailsField>
                <UserDetailsField>
                  <UserDetailsFieldLabel>
                    Data de cadastro
                  </UserDetailsFieldLabel>
                  <UserDetailsFieldValue>
                    {user?.created_at ? new Date(user.created_at).toLocaleDateString('pt-BR') : ''}
                  </UserDetailsFieldValue>
                </UserDetailsField>
              </UserDetailsRow>
              {user.legal_person && (
                <UserDetailsRow>
                  <UserDetailsField>
                    <UserDetailsFieldLabel>
                      CNPJ
                    </UserDetailsFieldLabel>
                    <UserDetailsFieldValue>
                      {maskCpfOrCnpj(user?.legal_person?.cnpj)}
                    </UserDetailsFieldValue>
                  </UserDetailsField>
                  <UserDetailsField>
                    <UserDetailsFieldLabel>
                      Razão social
                    </UserDetailsFieldLabel>
                    <UserDetailsFieldValue>
                      {user?.legal_person?.corporate_name}
                    </UserDetailsFieldValue>
                  </UserDetailsField>
                  <UserDetailsField>
                    <UserDetailsFieldLabel>
                      Nome fantasia
                    </UserDetailsFieldLabel>
                    <UserDetailsFieldValue>
                      {user?.legal_person?.trade_name}
                    </UserDetailsFieldValue>
                  </UserDetailsField>
                  <UserDetailsField>
                    <UserDetailsFieldLabel>
                      Responsável
                    </UserDetailsFieldLabel>
                    <UserDetailsFieldValue>
                      {user?.legal_person?.responsible_name}
                    </UserDetailsFieldValue>
                  </UserDetailsField>
                </UserDetailsRow>
              )}
              {user.individual_person && (
                <UserDetailsRow>
                  <UserDetailsField>
                    <UserDetailsFieldLabel>
                      CPF
                    </UserDetailsFieldLabel>
                    <UserDetailsFieldValue>
                      {maskCpfOrCnpj(user?.individual_person?.cpf)}
                    </UserDetailsFieldValue>
                  </UserDetailsField>
                  <UserDetailsField>
                    <UserDetailsFieldLabel>
                      Data de nascimento
                    </UserDetailsFieldLabel>
                    <UserDetailsFieldValue>
                      {user?.individual_person?.birth_date}
                    </UserDetailsFieldValue>
                  </UserDetailsField>

                </UserDetailsRow>
              )}
              <UserDetailsField>
                <UserDetailsFieldLabel>
                  Endereço
                </UserDetailsFieldLabel>
                <UserDetailsFieldValue>
                  {formatUserAddress(user)}
                </UserDetailsFieldValue>
              </UserDetailsField>
            </Section>
            {
              bankAccount && (
                <Section>
                  <SectionTitle>
                    Dados bancários
                  </SectionTitle>
                  <UserDetailsRow>
                    <UserDetailsField>
                      <UserDetailsFieldLabel>
                        Conta
                      </UserDetailsFieldLabel>
                      <UserDetailsFieldValue>
                        {bankAccount.account}
                      </UserDetailsFieldValue>
                    </UserDetailsField>
                    <UserDetailsField>
                      <UserDetailsFieldLabel>
                        Digito da conta
                      </UserDetailsFieldLabel>
                      <UserDetailsFieldValue>
                        {bankAccount.account_check_digit}
                      </UserDetailsFieldValue>
                    </UserDetailsField>
                    <UserDetailsField>
                      <UserDetailsFieldLabel>
                        Agência
                      </UserDetailsFieldLabel>
                      <UserDetailsFieldValue>
                        {bankAccount.agency}
                      </UserDetailsFieldValue>
                    </UserDetailsField>
                    <UserDetailsField>
                      <UserDetailsFieldLabel>
                        Digito da agência
                      </UserDetailsFieldLabel>
                      <UserDetailsFieldValue>
                        {bankAccount.agency_check_digit || '.'}
                      </UserDetailsFieldValue>
                    </UserDetailsField>
                  </UserDetailsRow>
                  <UserDetailsRow>
                    <UserDetailsField>
                      <UserDetailsFieldLabel>
                        Banco
                      </UserDetailsFieldLabel>
                      <UserDetailsFieldValue>
                        {bankAccount?.bank_code?.name}
                      </UserDetailsFieldValue>
                    </UserDetailsField>
                  </UserDetailsRow>
                </Section>
              )
            }

            <Section>
              <SectionTitle>
                Veículos
              </SectionTitle>

              <Table>
                <thead>
                  <tr>
                    <TableHeading>Placa</TableHeading>
                    <TableHeading>Tipo</TableHeading>
                    <TableHeading>Carroceria</TableHeading>
                    <TableHeading>Volume</TableHeading>
                    <TableHeading>Capacidade</TableHeading>
                  </tr>
                </thead>
                <tbody>
                  {
                        user.vehicles.map((vehicle) => (
                          <tr key={vehicle.id}>
                            <TableCell>{vehicle.plate}</TableCell>
                            <TableCell>{vehicle?.vehicle_type?.name || ''}</TableCell>
                            <TableCell>{vehicle?.vehicle_type?.bodywork?.name || ''}</TableCell>
                            <TableCell>{vehicle?.vehicle_type?.volume?.name || ''}</TableCell>
                            <TableCell>{vehicle?.vehicle_type?.weight?.name || ''}</TableCell>
                          </tr>
                        ))
                      }
                  {!user.vehicles.length && (
                  <tr>
                    <TableCell colSpan={5}>
                      Nenhum veículo encontrado
                    </TableCell>
                  </tr>
                  )}
                </tbody>
              </Table>
            </Section>

          </DetailsContainer>
        </ModalBody>
        )}
      </UserDetailsModalContainer>
    </UserDetailsModalRoot>
  );
};
