import { Routes, Route } from 'react-router';

import PrivateRoute from './PrivateRoute';

import { SignIn } from '../pages/SignIn';

import { Dashboard } from '../pages/Dashboard';
import { PoliticsAndPrivacy } from '../pages/PoliticsAndPrivacy';
import { Vehicles } from '../pages/Vehicles';
import { AddVehicleType } from '../pages/AddVehicleType';
import { EditVehicleType } from '../pages/EditVehicleType';
import { AddAdmin } from '../pages/AddAdmin';
import { Settings } from '../pages/Settings';
import { ManageDrivers } from '../pages/ManageDrivers';
import { FollowDelivery } from '../pages/FollowDelivery';
import { FinancialSettings } from '../pages/FinancialSettings';
import { ManageUsers } from '../pages/ManageUsers/ManageUsers';

export const Router = () => (
  <Routes>
    <Route path="/signIn" element={<SignIn />} />
    <Route
      path="/"
      element={(
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      )}
    />
    <Route
      path="/politicsAndPrivacies"
      element={(
        <PrivateRoute>
          <PoliticsAndPrivacy />
        </PrivateRoute>
      )}
    />
    <Route
      path="/vehicles"
      element={(
        <PrivateRoute>
          <Vehicles />
        </PrivateRoute>
      )}
    />
    <Route
      path="/vehicles/new"
      element={(
        <PrivateRoute>
          <AddVehicleType />
        </PrivateRoute>
      )}
    />
    <Route
      path="/vehicles/edit/:vehicleId"
      element={(
        <PrivateRoute>
          <EditVehicleType />
        </PrivateRoute>
      )}
    />
    <Route
      path="/newAdmin"
      element={(
        <PrivateRoute>
          <AddAdmin />
        </PrivateRoute>
      )}
    />
    <Route
      path="/settings"
      element={(
        <PrivateRoute>
          <Settings />
        </PrivateRoute>
      )}
    />
    <Route
      path="/manageDrivers"
      element={(
        <PrivateRoute>
          <ManageDrivers />
        </PrivateRoute>
      )}
    />
    <Route
      path="/manageUsers"
      element={(
        <PrivateRoute>
          <ManageUsers />
        </PrivateRoute>
      )}
    />
    <Route
      path="/followDelivery"
      element={(
        <PrivateRoute>
          <FollowDelivery />
        </PrivateRoute>
      )}
    />
    <Route
      path="financialSettings"
      element={(
        <PrivateRoute>
          <FinancialSettings />
        </PrivateRoute>
      )}
    />
  </Routes>
);
