import styled from 'styled-components';

import { ReactComponent as CloseIconRaw } from '../../../assets/images/vehicles/vehicle-close-icon.svg';

export const UserDetailsModalRoot = styled.div`
  position: fixed;
  inset: 0;
  z-index: 100;

  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UserDetailsModalContainer = styled.article`
  width: 100%;
  max-width: 72.375rem;
  min-height: 40vh;
  height: fit-content;
  overflow: hidden;

  border-radius: 0.625rem;
  background-color: #fff;
`;

export const ModalHeader = styled.header`
  width: 100%;
  height: 4.1875rem;

  display: flex;
  align-items: center;

  background-color: var(--softPurple);
`;

export const DetailsTitle = styled.h1`
  max-width: 20rem;
  max-height: 2.5rem;
  margin-left: 1.5625rem;

  display: inline-block;

  color: var(--darkPurple);
  font-size: 1.125rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  margin: 0 1.9875rem 0 auto;

  cursor: pointer;
  transition: filter 0.3s ease;
  background-color: transparent;
  border: none;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CloseIcon = styled(CloseIconRaw)`
  width: 1.325625rem;
  height: 1.325625rem;
  color: var(--darkPurple);
`;

export const ModalBody = styled.div`
  width: 100%;
  height: fit-content;
  max-height: calc(90vh - 4.1875rem);
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: var(--darkPurple) #e0e0e0;

  &::-webkit-scrollbar {
    width: 6px;
    padding: 0 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #e0e0e0;
    border-radius: 6px;
    margin-bottom: 1rem;
    margin-top: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--darkPurple);
    border-radius: 6px;
  }

  display: flex;
  flex-direction: column;

  @media (min-width: 605px) {
    flex-direction: row;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const Section = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SectionTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--darkPurple);
`;

export const UserDetailsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  gap: 1rem;
`;

export const UserDetailsField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const UserDetailsFieldLabel = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: var(--darkPurple);
`;

export const UserDetailsFieldValue = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: var(--darkPurple);
`;

export const VehiclesTableWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: none;
  border-spacing: 0;
  border: 1px solid #eee;

  border-radius: 10px;

  tbody tr:last-child {
    td {
      border-radius: 0 10px 10px 0;
      border-bottom: none;
    }
  }
`;

export const TableHeading = styled.th`
  text-align: left;
  padding: 1rem 2rem;
  font-weight: 500;
  font-size: .9375rem;
  background: #fafafa;
  border-bottom: 1px solid #eee;

  &:first-child {
    border-radius: 10px 0 0 0px;
  }

  &:last-child {
    border-radius: 0 10px 0px 0;
    text-align: center;
  }

`;

export const TableCell = styled.td`
  text-align: left;
  padding: 1rem  2rem;

  border-bottom: 1px solid #eee;

  font-size: .9375rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    text-align: center;
  }
`;
