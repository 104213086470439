import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  max-width: 30.08rem;
  height: fit-content;
  padding: 5.5rem 0 8.7375rem;

  border-radius: 1.8rem;
  background-color: #fff;
`;

export const FormWrapper = styled.div`
  width: 95%;
  max-width: 23.875rem;
  height: fit-content;
  margin: 0 auto;

  .sign-in-form__input{
    margin-top: 3.98125rem;
  }

  .sign-in-form__button{
    margin-top: 3.08125rem;
  }
`;

export const CheckContainer = styled.div`
  width: fit-content;
  margin: 2.7rem auto 0;
`;

export const CheckBox = styled.input`
  width: 1rem;
  height: 1rem;

  accent-color: var(--softBlue);
  cursor: pointer;
`;

export const CheckLabel = styled.label`
  margin-left: 0.5rem;

  color: var(--gray);
  font-size: 1.4375rem;
`;

export const WarningText = styled.p`
  width: 100%;
  margin-top: 1rem;

  transition: all 0.2s ease;

  color: #f00;
  font-size: 1rem;
  text-align: center;
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ShowPasswordButton = styled.button`
  border: none;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--gray);
  font-size: 2rem;

  position: absolute;
  bottom: 1rem;
  right: 0.5rem;

  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
